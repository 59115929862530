import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';

const propTypes = {
    label: PropTypes.string,
    type: PropTypes.string,
    readOnly: PropTypes.bool,
    requiredChar: PropTypes.bool,
};

const InputField = ({
    input,
    label,
    value,
    readOnly,
    requiredChar,
    isDisabled,
    meta: { touched, error, warning },
    isFocus,
    ...custome
}) => {
    const handleFocus = (e) => {
        if (isFocus) {
            e.target.select();
        }
    };

    return (
        <>
            {label ? (
                <label>
                    {/* <h6 className="panel-title sc-title"> */}
                    <h6 className="panel-title">{label}</h6>
                </label>
            ) : null}

            <div className="form-group">
                <input
                    {...input}
                    readOnly={readOnly}
                    onChange={(e) => input.onChange(e)}
                    onFocus={handleFocus}
                    disabled={isDisabled}
                    {...custome}
                />
                {touched &&
                    ((error && (
                        <small className="text-danger">{error}</small>
                    )) ||
                        (warning && (
                            <small className="text-warning">{warning}</small>
                        )))}
            </div>
        </>
    );
};
InputField.propTypes = propTypes;

export default InputField;
