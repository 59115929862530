import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import validate from './validate';
import InputField from '../../../_Shares/FormHelpers/InputField';
import customerApis from '../../../../apis/customer.api';
import alertUtils, { SWAL } from '../../../../utils/alert.util';

const FortgetPassChangePassForm = ({ onChangeStep, tokenInfo }) => {
    const onSubmitForm = (dataPost) => {
        alertUtils.showLoading('Đang gửi thông tin...');
        customerApis
            .forgetChangePass({ ...dataPost, ...tokenInfo })
            .then(({ status, data }) => {
                if (status !== 200) {
                    alertUtils.show(SWAL.TYPE.ERR, data.msg);
                    return;
                }
                alertUtils.closeLoading();
                onChangeStep(0);

                const ridirectToLogin = () => {
                    window.location.href = '/dang-nhap';
                };

                alertUtils.showTimerFunc({
                    type: SWAL.TYPE.SUCCESS,
                    title: 'Bạn đã thay đổi mật khẩu thành công',
                    msg: '<p>Hệ thống sẽ tự động chuyển sang trang <b>ĐĂNG NHẬP</b> trong <strong></strong> giây</p>',
                    func: ridirectToLogin,
                    timer: 5000,
                });
            });
    };
    return (
        <>
            <Form
                onSubmit={onSubmitForm}
                validate={validate}
                render={({ handleSubmit, invalid, submitting }) => (
                    <div className="lesson-slide-box">
                        <form onSubmit={handleSubmit}>
                            <Field
                                name="password"
                                component={InputField}
                                className="form-control"
                                type="password"
                                label="Mật khẩu mới"
                            />
                            <Field
                                name="confirmPassword"
                                component={InputField}
                                className="form-control"
                                type="password"
                                label="Xác nhận mật khẩu mới"
                            />

                            <button
                                type="submit"
                                className="thm-btn comment-form__btn"
                                disabled={invalid || submitting}
                            >
                                Gửi <i className="fa fa-angle-right" />
                            </button>
                        </form>
                    </div>
                )}
            ></Form>
        </>
    );
};

export default FortgetPassChangePassForm;
