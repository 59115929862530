import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import congratIcon from '../../../wwwroot/images/confetti.png';

const MySwal = withReactContent(Swal);

export const SWAL = {
    TYPE: {
        WARNING: 'warning',
        ERR: 'error',
        SUCCESS: 'success',
        INFO: 'info',
        QUESTION: 'question',
    },
};
const alertUtils = {
    /**==========================================
    * Hiển thị sweet alert
    * @param {string} type: loại cảnh báo
    * @param {string} msg: message
    * @param {boolean} isQuesion: có phải loại câu hỏi không
    ==========================================*/
    show(type, msg, isQuestion) {
        if (isQuestion) {
            return MySwal.fire({
                icon: type,
                title: 'Thông báo',
                html: msg,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy',
                showCancelButton: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
            });
        }
        return MySwal.fire({
            icon: type,
            title: 'Thông báo',
            html: msg,
        });
    },
    showTimer(type, msg, timer = 1000) {
        return MySwal.fire({
            icon: type,
            title: msg,
            timer: timer,
            timerProgressBar: true,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
                MySwal.hideLoading();
            },
        });
    },
    showTimerFunc({ type, title, msg, func, timer = 1000 }) {
        let timerInterval;
        MySwal.fire({
            icon: type,
            title: title,
            html: msg,
            timer: timer,
            didOpen: () => {
                timerInterval = setInterval(() => {
                    MySwal.getHtmlContainer().querySelector(
                        'strong'
                    ).textContent = (Swal.getTimerLeft() / 1000).toFixed(0);
                }, 100);
            },
            willClose: () => {
                clearInterval(timerInterval);
                func();
            },
        });
    },
    showLoading(msg) {
        return MySwal.fire({
            html: msg,
            timerProgressBar: true,
            allowOutsideClick: false,
            didOpen: () => {
                MySwal.showLoading();
            },
        });
    },
    closeLoading() {
        MySwal.close();
    },
    showCongrat(title, msg, isQuesion = false) {
        const imgIcon = `<img src="${congratIcon}"/>`;
        if (!isQuesion) {
            Swal.fire({
                iconHtml: imgIcon,
                title: title,
                html: msg,
                customClass: {
                    icon: 'no-border',
                },
            });
        } else {
            return Swal.fire({
                iconHtml: imgIcon,
                title: title,
                html: msg,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy',
                showCancelButton: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                customClass: {
                    icon: 'no-border',
                },
            });
        }
    },
};

export default alertUtils;
