import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'antd';
import { Field, Form } from 'react-final-form';
import { SaveOutlined, CloseOutlined } from '@ant-design/icons';
import InputField from '../../_Shares/FormHelpers/InputField';
import validate from './validate';
import customerApis from '../../../apis/customer.api';
import alertUtils, { SWAL } from '../../../utils/alert.util';

const ChangePassPopup = ({ isOpen, onClose }) => {
    const onSubmitForm = (dataChange) => {
        alertUtils.showLoading('Đang đổi mật khẩu...');
        customerApis.changePassword(dataChange).then(({ status, data }) => {
            if (status !== 200) {
                alertUtils.show(SWAL.TYPE.ERR, data.msg);
                return;
            }
            alertUtils.show(
                SWAL.TYPE.SUCCESS,
                'Bạn đã đổi mật khẩu thành công'
            );
            if (onClose) {
                onClose();
            }
        });
    };
    return (
        <>
            <Form
                onSubmit={onSubmitForm}
                validate={validate}
                render={({ handleSubmit, invalid, submitting }) => (
                    <form onSubmit={handleSubmit}>
                        <Modal
                            open={isOpen}
                            onCancel={onClose}
                            footer={[
                                <Button
                                    onClick={onClose}
                                    danger
                                    type="primary"
                                    icon={<CloseOutlined />}
                                >
                                    Hủy
                                </Button>,
                                <Button
                                    icon={<SaveOutlined />}
                                    key="submit"
                                    type="primary"
                                    onClick={handleSubmit}
                                    disabled={invalid || submitting}
                                >
                                    Đổi mật khẩu
                                </Button>,
                            ]}
                        >
                            <div className="col-sm-12">
                                <Field
                                    name="currentPassword"
                                    component={InputField}
                                    className="form-control"
                                    type="password"
                                    label="Mật khẩu hiện tại"
                                />
                            </div>
                            <div className="col-sm-12">
                                <Field
                                    name="newPassword"
                                    component={InputField}
                                    className="form-control"
                                    type="password"
                                    label="Mật khẩu mới"
                                />
                            </div>
                            <div className="col-sm-12">
                                <Field
                                    name="confirmPassword"
                                    component={InputField}
                                    className="form-control"
                                    type="password"
                                    label="Xác nhận mật khẩu mới"
                                />
                            </div>
                        </Modal>
                    </form>
                )}
            ></Form>
        </>
    );
};

ChangePassPopup.propTypes = {};

export default ChangePassPopup;
