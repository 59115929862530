import _axiosService from '../services/axiosService';

const lessonApis = {
    getStudyLessonMenu: (dataPost) => {
        return _axiosService.post('/WebLesson/GetStudyLessonMenu', dataPost);
    },
    getDirectGroupButton: (dataPost) => {
        return _axiosService.post(
            '/StudyCourse/GetDirectGroupButtonV2',
            dataPost
        );
    },
    checkTest: (dataPost) => {
        return _axiosService.post('/StudyCourse/CheckTest', dataPost);
    },
    rateLesson: (dataPost) => {
        return _axiosService.post('/LessonRate/DoRate', dataPost);
    },
    getListRateComment: (dataPost) => {
        return _axiosService.post('/LessonRate/GetList', dataPost);
    },
};

export default lessonApis;
