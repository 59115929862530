import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import GenderSelect from '../_Shares/Select/GenderSelect/Dynamic';
import OldVerGenderSelect from '../_Shares/Select/GenderSelect/OldVersion';
import JobSelect from '../_Shares/Select/JobSelect';
import LocationSelect from '../_Shares/Select/LocationSelect/LocationSelect';

const CustomerRegisterForm = () => {
    // const [cityId, setCityId] = useState();
    // const [districtId, setDistrictId] = useState();

    // const onCityChange = (s) => {
    //     setCityId(s);
    // };
    // const onDistrictChange = (s) => {
    //     setDistrictId(s);
    // };

    useEffect(() => {
        const form_name = '#register_customer_form';
        if ($(form_name).length > 0) {
            var fv = $(form_name)
                .formValidation({
                    framework: 'bootstrap',
                    err: {
                        /* container: 'tooltip'*/
                    },
                    icon: {
                        //valid: 'fa fa-check',
                        //invalid: 'fa fa-times',
                        //validating: 'fa fa-refresh'
                    },
                    fields: {
                        name: {
                            message: 'Họ tên không được để trống',
                            validators: {
                                notEmpty: {
                                    message: 'Họ tên không được để trống',
                                },
                            },
                        },
                        phone: {
                            message: 'Số điện thoại không được để trống',
                            validators: {
                                notEmpty: {
                                    message:
                                        'Số điện thoại không được để trống',
                                },
                                stringLength: {
                                    max: 10,
                                    message:
                                        'Số điện thoại không được quá 10 số',
                                },
                            },
                        },
                        password: {
                            message: 'Mật khẩu không được để trống',
                            validators: {
                                notEmpty: {
                                    message: 'Mật khẩu không được để trống',
                                },
                                stringLength: {
                                    min: 6,
                                    message:
                                        'Mật khẩu phải nhiều hơn hoặc bằng 6 kí tự',
                                },
                            },
                        },
                        confirmpassword: {
                            message: 'Xác nhận mật khẩu không được để trống',
                            validators: {
                                notEmpty: {
                                    message:
                                        'Mật khẩu xác nhận không được để trống',
                                },
                                identical: {
                                    field: 'password',
                                    message:
                                        'Mật khẩu và mật khẩu xác nhận không trùng nhau',
                                },
                            },
                        },
                        locationId: {
                            message: 'Thành phố không được để trống',
                            validators: {
                                notEmpty: {
                                    message: 'Thành phố không được để trống',
                                },
                            },
                        },
                        gender: {
                            message: 'Giới tính không được để trống',
                            validators: {
                                notEmpty: {
                                    message: 'Giới tính không được để trống',
                                },
                            },
                        },
                    },
                })
                .on('success.form.fv', function (e) {
                    e.preventDefault();

                    const dataPost = {
                        c_name: $(`${form_name} #name`).val(),
                        c_email: $(`${form_name} #email`).val(),
                        c_telephone: $(`${form_name} #phone`).val(),
                        c_password: $(`${form_name} #password`).val(),
                        l_id: $(`${form_name} #locationId`).val(),
                        jo_id: $(`${form_name} #jobId`).val(),
                        c_yearbirth: new Date().getFullYear(),
                        c_gender: $(`${form_name} #gender`).val(),
                    };

                    swalShowLoading('<p>Đang xử lý</p>');
                    _customerApis
                        .register(dataPost)
                        .then(({ status, data }) => {
                            $(form_name).data('formValidation').resetForm();
                            if (status !== 200) {
                                mySwal(SWAL_CONST.TYPE.WARNING, data.msg);
                                return;
                            }

                            const ridirectToLogin = () => {
                                window.location.href = '/dang-nhap';
                            };
                            mySwalShowTimer({
                                type: SWAL_CONST.TYPE.SUCCESS,
                                title: 'Đăng ký thành công',
                                msg: `<p>Tự động chuyển sang <b>Đăng nhập</b> trong <strong></strong> giây</p>`,
                                func: ridirectToLogin,
                                timer: 5000,
                            });
                        });
                });
        }
    }, []);

    return (
        <form
            name="register_customer_form"
            id="register_customer_form"
            className="comment-one__form"
            noValidate="novalidate"
        >
            <div className="row">
                <div className="col-xl-12">
                    <div className="comment-form__input-box form-group">
                        <label>
                            Họ tên <i class="required-char">*</i>
                        </label>
                        <input
                            type="text"
                            placeholder="Họ tên"
                            name="name"
                            className="form-control"
                            id="name"
                        />
                    </div>
                </div>
                <div className="col-xl-6">
                    <div className="comment-form__input-box form-group">
                        <label>
                            Điện thoại <i class="required-char">*</i>
                        </label>
                        <input
                            type="text"
                            placeholder="Điện thoại"
                            name="phone"
                            className="form-control"
                            id="phone"
                        />
                    </div>
                </div>
                <div className="col-xl-6">
                    <OldVerGenderSelect
                        title="Giới tính"
                        id="gender"
                        name="gender"
                        isRequired
                    ></OldVerGenderSelect>
                </div>
                <div className="col-xl-12">
                    <div className="comment-form__input-box form-group">
                        <label>Email</label>
                        <label className="desc">
                            <b>Lưu ý:</b> Vui lòng nhập chính xác để nhận mã
                            kích hoạt ngay lập tức
                        </label>

                        <input
                            type="email"
                            placeholder="Email"
                            name="email"
                            className="form-control"
                            id="email"
                        />
                    </div>
                </div>

                <div className="col-xl-6">
                    <LocationSelect
                        parentId="0"
                        title="Thành phố"
                        // onChange={onCityChange}
                        id="locationId"
                        name="locationId"
                        isRequired
                    />
                </div>
                {/* <div className="col-xl-6">
                    <LocationSelect
                        parentId={cityId}
                        title="Quận huyện"
                        onChange={onDistrictChange}
                        id="districtId"
                    />
                </div>
                <div className="col-xl-6">
                    <LocationSelect
                        parentId={districtId}
                        title="Phường xã"
                        id="wardId"
                    />
                </div> */}
                <div className="col-xl-6">
                    <JobSelect id="jobId" />
                </div>
                {/* <div className="col-xl-6">
                    <BirthYearSelect id="birthYear" />
                </div> */}
                <div className="col-xl-6">
                    <div className="comment-form__input-box form-group">
                        <label>
                            Mật khẩu <i class="required-char">*</i>
                        </label>
                        <input
                            type="password"
                            placeholder="Mật khẩu"
                            name="password"
                            className="form-control"
                            id="password"
                        />
                    </div>
                </div>
                <div className="col-xl-6">
                    <div className="comment-form__input-box form-group">
                        <label>
                            Xác nhận mật khẩu <i class="required-char">*</i>
                        </label>
                        <input
                            type="password"
                            placeholder="Xác nhận mật khẩu"
                            name="confirmpassword"
                            className="form-control"
                            id="confirmpassword"
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-12">
                    <div
                        className="comment-form__btn-box"
                        style={{ float: 'right' }}
                    >
                        <ul className="list-unstyled contact-page-two__info mt-10 mb-20">
                            <li>
                                <div
                                    className="text"
                                    style={{ marginLeft: '0px' }}
                                >
                                    <p>
                                        Bạn đã có tài khoản?{' '}
                                        <a href="/dang-nhap">
                                            <b>ĐĂNG NHẬP NGAY</b>
                                        </a>
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="comment-form__btn-box">
                        <button
                            type="submit"
                            className="thm-btn comment-form__btn"
                        >
                            ĐĂNG KÝ <i className="fa fa-angle-right" />
                        </button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default CustomerRegisterForm;

document.querySelectorAll('react-web-register-form').forEach((domContainer) => {
    const root = createRoot(domContainer);
    root.render(creElm(CustomerRegisterForm, {}));
});
