import Resizer from 'react-image-file-resizer';
import { GLOBAL } from '../constants/global.const';
const { IMAGE_RESIZE_CONFIG, IMAGE_TYPE } = GLOBAL;
const imageUtils = {
    /**
     * Resize hình ảnh
     * @param  onChange : function
     * @param file : file truyền vào
     * @param  maxWidth : Chiều rộng tối đa
     * @param  maxHeight : Chiều cao tối đa
     * @param  imageType : Loại hình ảnh muốn resize thành
     * @param  quality : Chất lượng hình ảnh
     * @param rotate : góc xoay hình ảnh
     * @param  outputType : dạng hình ảnh trả về (base64 or blob)
     */
    resize(
        onChange,
        file,
        maxWidth = IMAGE_RESIZE_CONFIG.MAX_WIDTH,
        maxHeight = IMAGE_RESIZE_CONFIG.MAX_HEIGHT,
        imageType = IMAGE_TYPE.JPEG,
        quality = IMAGE_RESIZE_CONFIG.QUALITY.PERCENT_80,
        rotate = IMAGE_RESIZE_CONFIG.ROTATION.DEG_0,
        outputType = IMAGE_RESIZE_CONFIG.OUT_PUT_TYPE.BASE64
    ) {
        Resizer.imageFileResizer(
            file,
            maxWidth,
            maxHeight,
            imageType,
            quality,
            rotate,
            (uri) => {
                if (onChange) {
                    const data = {
                        file,
                        base64Content: uri,
                    };
                    onChange(data);
                }
                return uri;
            },
            outputType
        );
        return onChange;
    },
};

export default imageUtils;
