import React, { useEffect, useState } from 'react';

const LocationSelect = ({
    title,
    parentId,
    onChange,
    isRequired,
    ...props
}) => {
    const [list, setList] = useState([]);

    const fetchData = async () => {
        const { data, status } = await _locationApis.getLocationByParent(
            parentId
        );
        if (status !== 200) {
            setList([]);
            return;
        }
        setList(data.data);
        if (onChange && data.data.length > 0) {
            onChange(data.data[0].l_id);
        }
    };

    useEffect(() => {
        if (parentId !== undefined) {
            fetchData();
        }
    }, [parentId]);

    useEffect(() => {
        if (props.id) {
            $(`#${props.id}`).select2({
                placeholder: '--- Vui lòng chọn ---',
            });
        }
    }, [props.id]);

    const handChange = (s) => {
        if (onChange) {
            onChange(s.target.value);
        }
    };

    const showHtml = () => {
        let xhtml = null;
        if (list.length > 0) {
            xhtml = list.map((o, index) => {
                return (
                    <option key={index} value={o.l_id}>
                        {o.l_name}
                    </option>
                );
            });
        }
        return (
            <Fragment>
                <option></option>
                {xhtml}
            </Fragment>
        );
    };

    return (
        <div className="comment-form__input-box form-group" id="my-select-2">
            <label>
                {title} {isRequired ? <i class="required-char">*</i> : null}
            </label>
            <select className="form-control" onChange={handChange} {...props}>
                {showHtml()}
            </select>
        </div>
    );
};

export default LocationSelect;
