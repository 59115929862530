const validate = (values) => {
    const errors = {};
    const { password, confirmPassword } = values;

    if (!password) {
        errors.password = 'Mật khẩu mới không được để trống';
    }
    if (!confirmPassword) {
        errors.confirmPassword = 'Mật khẩu xác nhận không được để trống';
    }

    if (password !== confirmPassword) {
        errors.confirmPassword =
            'Mật khẩu mới và xác nhận mật khẩu không trùng nhau';
    }

    return errors;
};
export default validate;
