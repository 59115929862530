import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _courseApis from '../../../apis/course.api';
import { GLOBAL } from '../../../constants/global.const';
import CommentItem from './Item';
import { createRoot } from 'react-dom/client';
import BcnPagination from '../../_Shares/Pagination';

const CourseCommentList = ({ courseId }) => {
    const [list, setList] = useState([]);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const reset = () => {
        setList([]);
        setTotal(0);
    };
    const fetchData = async (currPage) => {
        const { data, status } = await _courseApis.getCommentList({
            courseId,
            currentPage: currPage,
            pageSize: GLOBAL.COMMENT.PAGE_SIZE,
        });
        if (status !== 200) {
            reset();
            return;
        }

        setList(data.data);
        setTotal(data.total);
    };
    useEffect(() => {
        if (courseId) {
            fetchData(1);
        }
    }, [courseId]);

    const onPageChange = (currPage) => {
        setCurrentPage(currPage);
        fetchData(currPage);
    };

    const showHtml = () => {
        if (list.length === 0) return null;
        return list.map((o, index) => {
            return <CommentItem dataItem={o} key={index} />;
        });
    };
    return (
        <div className="course-details__bottom">
            <div className="comment-one">
                <h3 className="comment-one__title">
                    {total > 0 ? `${total} Bình luận` : 'Chưa có bình luận nào'}{' '}
                </h3>
                {showHtml()}
                <BcnPagination
                    total={total}
                    currentCount={list.length}
                    pageSize={GLOBAL.COMMENT.PAGE_SIZE}
                    currentPage={currentPage}
                    onChange={onPageChange}
                ></BcnPagination>
            </div>
        </div>
    );
};

CourseCommentList.propTypes = {};

export default CourseCommentList;

document.querySelectorAll('react-course-comment').forEach((domContainer) => {
    const courseId = parseInt(domContainer.dataset.courseid, 10);

    const root = createRoot(domContainer);
    root.render(<CourseCommentList courseId={courseId} />);
});
