/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import PropTypes from 'prop-types';
import { Pagination } from 'antd';

const propTypes = {
    currentPage: PropTypes.number,
    pageSize: PropTypes.number,
    total: PropTypes.number,
    onChange: PropTypes.func,
    currentCount: PropTypes.number,
};

const BcnPagination = ({
    currentPage,
    pageSize,
    total,
    onChange,
    currentCount,
    title,
}) => {
    title = title ? title : 'Hiển thị bản ghi ';
    return (
        <div style={{ marginTop: '20px' }}>
            {total <= pageSize ? (
                <div />
            ) : (
                <div className="text-center">
                    <div
                        className="text-center"
                        style={{ marginBottom: '10px' }}
                    >
                        <p
                            style={{ marginBottom: '0px' }}
                            className="text-muted"
                        >
                            {title}
                            <span className="text-black">
                                {(currentPage - 1) * pageSize + 1}
                                {' - '}
                                {(currentPage - 1) * pageSize +
                                    (currentCount > pageSize
                                        ? pageSize
                                        : currentCount)}
                            </span>
                            {' của '}
                            <span className="text-black">{total}</span>
                        </p>
                    </div>
                    <Pagination
                        current={currentPage}
                        defaultPageSize={pageSize}
                        total={total}
                        onChange={(page) => onChange(page)}
                        showSizeChanger={false}
                    />
                </div>
            )}
        </div>
    );
};

BcnPagination.propTypes = propTypes;

export default BcnPagination;
