import React from 'react';
import PropTypes from 'prop-types';
import ReactStars from 'react-rating-stars-component';

const RatingField = ({
    input,
    label,
    maxStar,
    meta: { touched, error, warning },
}) => {
    maxStar = maxStar !== undefined ? maxStar : 5;
    const handChange = (s) => {
        input.onChange(s);
    };
    return (
        <>
            <>
                {label ? (
                    <label>
                        <h6 className="panel-title">{label}</h6>
                    </label>
                ) : null}
            </>
            <div className="form-group">
                <ReactStars
                    size={30}
                    count={maxStar}
                    color="gray"
                    activeColor={'orange'}
                    value={input.value || 0}
                    a11y={false}
                    isHalf={false}
                    emptyIcon={<i className="far fa-star" />}
                    halfIcon={<i className="fa fa-star-half-alt" />}
                    filledIcon={<i className="fa fa-star" />}
                    onChange={handChange}
                />
                {touched &&
                    ((error && (
                        <small className="text-danger">{error}</small>
                    )) ||
                        (warning && (
                            <small className="text-warning">{warning}</small>
                        )))}
            </div>
        </>
    );
};

RatingField.propTypes = {};

export default RatingField;
