import React, { useState } from 'react';
import _lessonApis from '../../../apis/lesson.api';
import PrevButton from './PrevButton';
import NextButton from './NextButton';
import CompleteButton from './CompleteButton';
import QuizPopup from '../QuizPopup';
import _alertUtils from '../../../utils/alert.util';
// import CompleteCoursePopup from '../../Course/FinishRate/Popup';

const LessonDirectGroupBtn = ({ dataItem, lessonId, onReload }) => {
    const [showTestPopup, setShowTestPopup] = useState(false);

    const onComplete = async () => {
        _alertUtils.showLoading('Đang kiểm tra...');
        const { status, data } = await _lessonApis.checkTest({ lessonId });
        if (status !== 200) {
            mySwal(SWAL_CONST.TYPE.ERR, `Xảy ra lỗi: ${data.msg}`);
            return;
        }

        _alertUtils.closeLoading();
        // Không có bài kiểm tra và không phải bài cuối khóa
        if (!data.isTest && !data.isLastOfCourse) {
            window.location.href = data.nextLessonUrl;
            return;
        }

        // Không có bài kiểm tra và là bài cuối khóa
        if (!data.isTest && data.isLastOfCourse) {
            // Hiển thị popup đánh giá và bình luận cuối khóa
            //setShowCompletePopup(true);
            swalShowLoading();
            window.location.reload();
            return;
        }

        setShowTestPopup(data.isTest);
    };

    const onCloseTestPopup = (isReloadContent, isReloadPage) => {
        if (isReloadPage) {
            window.location.reload();
            return;
        }
        if (isReloadContent) {
            onReload();
        }
        setShowTestPopup(false);
    };
    return (
        <Fragment>
            <div className="comment-form col-12">
                {showTestPopup ? (
                    <QuizPopup
                        isShow={showTestPopup}
                        lessonId={lessonId}
                        onClose={onCloseTestPopup}
                    />
                ) : null}

                <div className="row">
                    <div className="col-4">
                        <PrevButton link={dataItem.prevLink} />
                    </div>
                    <div className="col-4 text-center">
                        <CompleteButton
                            dataItem={dataItem}
                            onClick={onComplete}
                        />
                    </div>
                    <div className="col-4 text-right">
                        <NextButton link={dataItem.nextLink} />
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default LessonDirectGroupBtn;
