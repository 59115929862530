import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import '../../../assets/css/quiz.scss';
import Quiz from '../../../models/quiz.model';
import _ from 'lodash';
import QuizAnswerItem from './AnswerItem';
import { GLOBAL } from '../../../constants/global.const';
import _alertUtils from '../../../utils/alert.util';
import QuizResult from './Result';

const propTypes = {
    isShow: PropTypes.bool,
    lessonId: PropTypes.number,
};

const QuizPopup = ({ isShow, lessonId, onClose }) => {
    const [testInfo, setTestInfo] = useState({
        currentTest: {},
        totalQuestion: 0,
        currentQuest: {},
        listAnswer: [],
        currentIndex: 0,
    });

    const [showResult, setShowResult] = useState(false);

    var quiz = useMemo(() => {
        return new Quiz({ lessonId });
    }, [lessonId]);

    console.log(quiz);
    const fetchData = async () => {
        const res = await quiz.getQuestion();
        if (!res.isSuccess) return;
        setTestInfo(res.data);
    };
    useEffect(() => {
        if (isShow && lessonId) {
            fetchData();
        }
    }, [isShow, lessonId]);

    const doAnswer = async (answerId) => {
        const res = await quiz.doAnswer(answerId);
        if (res.isFinish) {
            const testType = quiz.currentTest.t_type;
            switch (testType) {
                case GLOBAL.TEST_TYPE.BAI_KHAO_SAT:
                case GLOBAL.TEST_TYPE.NHAP_DAP_AN: {
                    onClose(true, false);
                    _alertUtils.showCongrat(
                        'Đã hoàn thành',
                        ` <p>Cảm ơn bạn đã trả lời các câu hỏi của chúng tôi.</p>
                        <p>Chúc bạn có những bài học thật vui và bổ ích</p>`
                    );
                    return;
                }
                case GLOBAL.TEST_TYPE.CUOI_KHOA:
                case GLOBAL.TEST_TYPE.TINH_DIEM:
                case GLOBAL.TEST_TYPE.TINH_TI_LE: {
                    setShowResult(true);
                }
                default: {
                    return;
                }
            }
        }

        setTestInfo((prevState) => {
            return {
                ...prevState,
                ...res,
            };
        });
    };

    const handleClose = () => {
        if (onClose) {
            if (quiz.currentTest.t_type === GLOBAL.TEST_TYPE.CUOI_KHOA) {
                onClose(true, true);
                return;
            }
            onClose(true, false);
        }
    };

    const showAnswer = () => {
        if (!testInfo.listAnswer) return null;
        return testInfo.listAnswer.map((item, index) => {
            return (
                <QuizAnswerItem
                    dataItem={item}
                    key={index}
                    doAnswer={doAnswer}
                />
            );
        });
    };

    const showContent = () => {
        return showResult ? (
            <>
                <QuizResult quizInfo={quiz}></QuizResult>
            </>
        ) : (
            <>
                <div className="test-content__question">
                    <div className="title">
                        Câu hỏi số {testInfo.currentIndex + 1}/
                        {testInfo.totalQuestion}
                    </div>
                    <div className="name">{testInfo.currentTest.t_name}</div>
                    <div className="test-content__desc">
                        {testInfo.currentQuest.tq_content}
                    </div>
                </div>
                <div className="test-content__answer-list">{showAnswer()}</div>
            </>
        );
    };
    return (
        <>
            {!_.isEmpty(testInfo) ? (
                <Modal
                    style={{ top: 20 }}
                    open={isShow}
                    footer={null}
                    width={1000}
                    className="quiz-modal"
                    onCancel={handleClose}
                >
                    <div className="test-content">{showContent()}</div>
                </Modal>
            ) : null}
        </>
    );
};

QuizPopup.propTypes = propTypes;

export default QuizPopup;
