const validate = (values) => {
    const errors = {};
    const { c_name, c_email, c_telephone } = values;

    if (!c_name) {
        errors.c_name = 'Họ tên không được để trống';
    }
    if (!c_email) {
        errors.c_email = 'Email không được để trống';
    }
    if (!c_telephone) {
        errors.c_telephone = 'Số điện thoại không được để trống';
    }

    return errors;
};
export default validate;
