import React from 'react';
import { Field, Form } from 'react-final-form';
import RatingField from '../../_Shares/FormHelpers/RatingField';
import TextAreaField from '../../_Shares/FormHelpers/TextAreaField';
import validate from './formValidate';

const LessonRateCommentForm = ({ onSubmitForm }) => {
    return (
        <Form
            onSubmit={onSubmitForm}
            validate={validate}
            render={({ handleSubmit, invalid, submitting }) => (
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-xl-12">
                            <Field
                                name="ratePoint"
                                component={RatingField}
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="mb-20"></div>
                    <div className="row">
                        <div className="col-xl-12">
                            <Field
                                name="comment"
                                component={TextAreaField}
                                className="form-control"
                                cols="30"
                                rows="4"
                                placeholder="Nhập nội dung..."
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12">
                            <div className="comment-form__btn-box">
                                <button
                                    type="submit"
                                    className="thm-btn comment-form__btn"
                                    disabled={invalid || submitting}
                                >
                                    GỬI ĐÁNH GIÁ & BÌNH LUẬN
                                    <i className="fa fa-angle-right" />
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            )}
        ></Form>
    );
};

LessonRateCommentForm.propTypes = {};

export default LessonRateCommentForm;
