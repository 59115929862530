import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import validate from './validate';
import InputField from '../../../_Shares/FormHelpers/InputField';
import customerApis from '../../../../apis/customer.api';
import alertUtils, { SWAL } from '../../../../utils/alert.util';

const ForgetPassCodeForm = ({ onChangeStep, tokenInfo }) => {
    const onSubmitForm = (dataPost) => {
        let dataRes = {
            code: dataPost.code,
        };
        alertUtils.showLoading('Đang gửi thông tin...');
        customerApis
            .checkCodeReset({ ...dataPost, ...tokenInfo })
            .then(({ status, data }) => {
                if (status !== 200) {
                    alertUtils.show(SWAL.TYPE.ERR, data.msg);
                    return;
                }
                alertUtils.closeLoading();

                dataRes = {
                    ...dataRes,
                    id: data.data,
                };

                if (onChangeStep) {
                    onChangeStep(3, dataRes);
                }
            });
    };
    return (
        <>
            <Form
                onSubmit={onSubmitForm}
                validate={validate}
                render={({ handleSubmit, invalid, submitting }) => (
                    <div className="lesson-slide-box">
                        <form onSubmit={handleSubmit}>
                            <label htmlFor="forgetCode">
                                Xin vui lòng nhập mã <b>CODE</b> mà chúng tôi đã{' '}
                                <b>gửi cho bạn thông qua email/số điện thoại</b>
                            </label>
                            <Field
                                name="code"
                                component={InputField}
                                className="form-control"
                                type="text"
                            />

                            <button
                                type="submit"
                                className="thm-btn comment-form__btn"
                                disabled={invalid || submitting}
                            >
                                Gửi <i className="fa fa-angle-right" />
                            </button>
                        </form>
                    </div>
                )}
            ></Form>
        </>
    );
};

export default ForgetPassCodeForm;
