import axios from 'axios';

const getHeaders = () => {
    const headers = {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
    };
    return headers;
};

const axiosService = {
    get(url, type = '', token = '') {
        return this.doRequest('GET', url, '', type);
    },
    post(url, data, type = '', token = '') {
        return this.doRequest('POST', url, data, type);
    },

    put(url, data, type = '', token = '') {
        return this.doRequest('PUT', url, data, type);
    },

    delete(url, type = '', token = '') {
        return this.doRequest('DELETE', url, '', type);
    },

    doRequest(method, url, data) {
        let timeOut = 0;
        const headers = getHeaders();
        return axios({
            method,
            url,
            data,
            headers,
            timeout: timeOut,
        })
            .then((res) => res)
            .catch((err) => {
                const resp = err.response;
                if (!resp) {
                    return {
                        status: 503,
                        data: {
                            msg: 'Không kết nối được đến network',
                        },
                    };
                }
                switch (resp.status) {
                    case 404:
                    case 405:
                    case 500:
                    case 400: {
                        return resp;
                    }
                    case 401: {
                        return '';
                    }
                    default:
                        return resp;
                }
            });
    },
};

export default axiosService;
