import React from 'react';
import GenderSelect from './Dynamic';

const GenderSelectField = ({ input, onChange, label, isDisabled }) => {
    const { value: inputValue, onChange: inputOnChange } = input;

    const handleChange = (value) => {
        inputOnChange(value);
        if (onChange) {
            onChange(value);
        }
    };
    return (
        <GenderSelect
            defaultValue={inputValue}
            label={label}
            onChange={(value) => handleChange(value)}
            isDisabled={isDisabled}
        />
    );
};

export default GenderSelectField;
