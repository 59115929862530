const validate = (values) => {
    const errors = {};
    const { code } = values;

    if (!code) {
        errors.code = 'Mã CODE không được để trống';
    }

    return errors;
};
export default validate;
