const validate = (values) => {
    const errors = {};
    const { username } = values;

    if (!username) {
        errors.username = 'Email/Số điện thoại không được để trống';
    }

    return errors;
};
export default validate;
