import React from 'react';
import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';
import { Dropdown } from 'antd';

const UserMenu = ({ userName, isMobile }) => {
    const items = [
        {
            key: '1',
            label: (
                <a rel="noopener noreferrer" href="/khoa-hoc-cua-toi">
                    Khóa học của tôi
                </a>
            ),
        },
        {
            key: '2',
            label: (
                <a rel="noopener noreferrer" href="/thong-tin-tai-khoan">
                    Thông tin tài khoản
                </a>
            ),
        },
    ];
    const showHtml = () => {
        return isMobile === 'true' ? (
            <span className="user-menu__name">
                <i className="fa fa-user mr-5"></i>
                {userName}
            </span>
        ) : (
            <a
                href="/khoa-hoc-cua-toi"
                rel="noopener noreferrer"
                className="user-menu__name"
            >
                <i className="fa fa-user mr-5"></i>
                {userName}
            </a>
        );
    };
    return (
        <div className="user-menu">
            <Dropdown
                menu={{
                    items,
                }}
                placement="bottomLeft"
            >
                {showHtml()}
            </Dropdown>
        </div>
    );
};

UserMenu.propTypes = {};

export default UserMenu;

document.querySelectorAll('react-user-menu').forEach((domContainer) => {
    const userName = domContainer.dataset.userName;
    const isMobile = domContainer.dataset.isMobile;

    const root = createRoot(domContainer);
    root.render(<UserMenu userName={userName} isMobile={isMobile} />);
});
