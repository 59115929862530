import React, { useState, useEffect, useRef } from 'react';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';

const DatePickerField = ({
    input,
    label,
    maxDate,
    minDate,
    showTime,
    isDisabled,
    meta: { error },
}) => {
    const [dateValue, setDateValue] = useState();
    const dateFormat = showTime ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY';
    const isFirst = useRef(true);

    useEffect(() => {
        let _dateValue = dayjs(new Date());

        if (input.value) {
            _dateValue = dayjs(input.value);
        }

        setDateValue(_dateValue);
        input.onChange(_dateValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isFirst.current && input.value) {
            setDateValue(dayjs(input.value));
        }
    }, [input.value]);

    const handleChange = (e) => {
        isFirst.current = false;
        input.onChange(e);
        setDateValue(e);
    };

    const disabledDate = (current) => {
        if (maxDate && minDate) {
            return minDate > current && current < maxDate;
        }
        if (minDate) {
            return minDate > current;
        }
        if (maxDate) {
            return current > maxDate;
        }
        return false;
    };

    return (
        <div>
            {label ? (
                <label>
                    <h6 className="panel-title">{label}</h6>
                </label>
            ) : null}
            <div>
                <DatePicker
                    onChange={(s) => handleChange(s)}
                    format={dateFormat}
                    allowClear={false}
                    showToday={false}
                    disabledDate={(current) => disabledDate(current)}
                    showTime={showTime}
                    value={dateValue}
                    className="my-datepicker"
                    disabled={isDisabled}
                />
                {error && <small>{error}</small>}
            </div>
        </div>
    );
};

export default DatePickerField;
