const validate = (values) => {
    const errors = {};
    const { currentPassword, newPassword, confirmPassword } = values;

    if (!currentPassword) {
        errors.currentPassword = 'Mật khẩu hiện tại không được để trống';
    }
    if (!newPassword) {
        errors.newPassword = 'Mật khẩu mới không được để trống';
    }
    if (!confirmPassword) {
        errors.confirmPassword = 'Mật khẩu xác nhận không được để trống';
    }
    if (newPassword !== confirmPassword) {
        errors.confirmPassword =
            'Mật khẩu mới và xác nhận mật khẩu không giống nhau';
    }

    return errors;
};
export default validate;
