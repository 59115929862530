import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import LessonRateCommentForm from '../../Lesson/RateComment/Form';
import { createRoot } from 'react-dom/client';
import _courseApis from '../../../apis/course.api';
import './_style.scss';
import alertUtils, { SWAL } from '../../../utils/alert.util';

const propTypes = {
    courseId: PropTypes.number,
    lessonId: PropTypes.number,
};

const FinishCourseRateForm = ({ courseId, lessonId, isScroll }) => {
    useEffect(() => {
        if (isScroll) {
            var scrollDiv =
                document.getElementById('blog-detail').offsetTop - 70;
            window.scrollTo({ top: scrollDiv, behavior: 'smooth' });
        }
    }, [isScroll]);
    const onSave = (dataPost) => {
        dataPost = {
            ...dataPost,
            courseId,
            lessonId,
        };
        alertUtils.showLoading('Đang gửi thông tin');
        _courseApis.doRateFinish(dataPost).then(({ status, data }) => {
            alertUtils.closeLoading();
            if (status !== 200) {
                alertUtils.show(
                    SWAL.TYPE.ERR,
                    `Đã xảy ra lỗi trong quá trình đánh giá. Lỗi: ${data?.msg}`
                );
                return;
            }
            alertUtils
                .showTimer(SWAL.TYPE.SUCCESS, 'Bạn đã đánh giá thành công')
                .then(() => {
                    alertUtils.showLoading('Đang tải lại thông tin');
                    window.location.reload();
                });
        });
    };
    return (
        <div className="last-course-rate-form">
            <h4 className="lcrf-title">
                Chúc mừng Bạn đã học xong tất cả bài học
            </h4>
            <h5 className="lcrf-desc">
                Để hoàn thành xin vui lòng để lại đánh giá và cảm nhận <br />
                của bạn về khóa học nhé
            </h5>
            <LessonRateCommentForm onSubmitForm={onSave} />
        </div>
    );
};

FinishCourseRateForm.propTypes = propTypes;

export default FinishCourseRateForm;

document
    .querySelectorAll('react-finish-course-rate')
    .forEach((domContainer) => {
        const courseId = parseInt(domContainer.dataset.courseid, 10);
        const lessonId = parseInt(domContainer.dataset.lessonid, 10);
        const isScroll = domContainer.dataset.isscroll;
        const root = createRoot(domContainer);
        root.render(
            <FinishCourseRateForm
                courseId={courseId}
                lessonId={lessonId}
                isScroll={isScroll}
            />
        );
    });
