import React, { useEffect, useState } from 'react';
import _courseApis from '../../apis/course.api';

const CourseProgressInfo = ({ courseId }) => {
    const [progressInfo, setProgressInfo] = useState();
    const fetchData = async () => {
        const { status, data } = await _courseApis.getProgress({ courseId });
        if (status === 200) {
            setProgressInfo(data.data);
        }
    };
    useEffect(() => {
        if (courseId) {
            fetchData();
        }
    }, [courseId]);
    return (
        <Fragment>
            <div className="course-details__bottom">
                <div className="row">
                    {progressInfo ? (
                        <div className="col-xl-7 col-lg-7">
                            <div className="course-details__bottom-left">
                                <div className="team-details__progress">
                                    <div className="team-details__progress-single">
                                        <h4 className="team-details__progress-title">
                                            Tỷ lệ hoàn thành{' '}
                                            <span>
                                                ({progressInfo.completeLesson}/
                                                {progressInfo.totalLesson} mục)
                                            </span>
                                        </h4>
                                        <div className="bar">
                                            <div
                                                className="bar-inner count-bar counted"
                                                data-percent={`${progressInfo.completePercent}%`}
                                                style={{
                                                    width: `${progressInfo.completePercent}%`,
                                                }}
                                            >
                                                <div className="count-text" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="team-details__progress-single">
                                        <h4 className="team-details__progress-title">
                                            Thời gian đã học{' '}
                                            <span>
                                                ({progressInfo.studyTime}/
                                                {progressInfo.totalTime} ngày)
                                            </span>
                                        </h4>
                                        <div className="bar">
                                            <div
                                                className="bar-inner count-bar counted"
                                                data-percent={`${progressInfo.studyPercent}%`}
                                                style={{
                                                    width: `${progressInfo.studyPercent}%`,
                                                }}
                                            >
                                                <div className="count-text" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </Fragment>
    );
};

export default CourseProgressInfo;
