const validate = (values) => {
    const errors = {};
    const { comment, ratePoint } = values;

    if (!comment) {
        errors.comment = 'Nội dung bình luận không được để trống';
    }
    if (!ratePoint) {
        errors.ratePoint = 'Đánh giá không được để trống';
    }

    return errors;
};
export default validate;
