const validate = (values) => {
    const errors = {};
    const { formCode } = values;

    if (!formCode) {
        errors.formCode = 'Vui lòng nhập mã kích hoạt';
    }

    return errors;
};
export default validate;
