export const COURSE_STATUS = {
    CHUA_DANG_KY: 0,
    VUA_TAO: 1,
    DA_CAP_MA: 2,
    DA_KICH_HOAT: 3,
    DANG_HOC: 4,
    HOAN_THANH: 5,
    KHONG_HOAN_THANH: 6,
    HUY_DANG_KY: 7,
};
