import _axiosService from '../services/axiosService';

const testApis = {
    getQuestion: (dataPost) => {
        return _axiosService.post('/Test/GetQuestion', dataPost);
    },
    doAnswer: (dataPost) => {
        return _axiosService.post('/Test/DoAnswerQuestion', dataPost);
    },
    getNextQuestion: (dataPost) => {
        return _axiosService.post('/Test/GetNextQuestion', dataPost);
    },
    doFinish: (dataPost) => {
        return _axiosService.post('/Test/DoFinish', dataPost);
    },
};

export default testApis;
