// Khai báo những component cần nhúng ra ngoài site
import './assets/css/app.scss';
import LessonFooter from './components/Lesson/Footer';
import FinishCourseRateForm from './components/Course/FinishRate/Form';
import CourseActiveForm from './components/Course/ActiveForm';
import StarPercentage from './components/_Shares/StarPercentage';
import CourseSummaryRate from './components/Lesson/RateComment/SummaryRate';
import UserMenu from './components/User/UserMenu';
import UserInfo from './components/User/UserInfo';
import LoginForm from './components/User/LoginForm';
import ForgetPasswordForm from './components/User/ForgetPassword';
import RegisterForm from './components/User/RegisterForm';
import StudyStartButton from './components/Course/StudyStartButton';
