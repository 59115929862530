import React from 'react';
import PropTypes from 'prop-types';

const CommentItem = ({ dataItem }) => {
    const showRate = (ratePoint) => {
        let xhtml = [];
        for (let i = 1; i <= 5; i++) {
            if (i <= ratePoint) {
                xhtml.push(<span className="fa fa-star checked" key={i} />);
            } else {
                xhtml.push(<span className="fa fa-star" key={i} />);
            }
        }
        return xhtml;
    };
    return (
        <div className="comment-one__single">
            <div className="comment-one__image">
                <img
                    src={dataItem.avatar ? dataItem.avatar : '/images/user.png'}
                    alt=""
                />
            </div>

            <div className="comment-one__content">
                <h3>{dataItem.customerName}</h3>
                <p className="comment_rate">{showRate(dataItem.ratePoint)}</p>
                <p className="comment_detail">{dataItem.comment}</p>
            </div>
        </div>
    );
};

CommentItem.propTypes = {};

export default CommentItem;
