import _axiosService from '../services/axiosService';

const customerApis = {
    getInfo: () => {
        return _axiosService.post('/WebCustomer/GetInfo');
    },
    updateInfo: (dataPost) => {
        return _axiosService.post('/WebCustomer/UpdateInfo', dataPost);
    },
    changePassword: (dataPost) => {
        return _axiosService.post('/WebCustomer/ChangePassword', dataPost);
    },
    requireChangePass: (dataPost) => {
        return _axiosService.post(
            '/WebCustomer/ForgetPassword/RequireResetPassword',
            dataPost
        );
    },
    checkCodeReset: (dataPost) => {
        return _axiosService.post(
            '/WebCustomer/ForgetPassword/CheckCodeReset',
            dataPost
        );
    },
    forgetChangePass: (dataPost) => {
        return _axiosService.post(
            '/WebCustomer/ForgetPassword/ChangePassword',
            dataPost
        );
    },
};

export default customerApis;
