import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import validate from './validate';
import InputField from '../../../_Shares/FormHelpers/InputField';
import customerApis from '../../../../apis/customer.api';
import alertUtils, { SWAL } from '../../../../utils/alert.util';

const ForgetPassRequireForm = ({ onChangeStep }) => {
    const onSubmitForm = (dataPost) => {
        alertUtils.showLoading('Đang gửi thông tin...');
        customerApis.requireChangePass(dataPost).then(({ status, data }) => {
            if (status !== 200) {
                alertUtils.show(SWAL.TYPE.ERR, data.msg);
                return;
            }
            alertUtils.closeLoading();
            if (onChangeStep) {
                onChangeStep(2, { id: data.data });
            }
        });
    };
    return (
        <>
            <Form
                onSubmit={onSubmitForm}
                validate={validate}
                render={({ handleSubmit, invalid, submitting }) => (
                    <div className="lesson-slide-box">
                        <form onSubmit={handleSubmit}>
                            <Field
                                name="username"
                                component={InputField}
                                className="form-control"
                                type="text"
                                label="Nhập email/số điện thoại dùng để đăng ký tài khoản"
                            />

                            <button
                                type="submit"
                                className="thm-btn comment-form__btn"
                                disabled={invalid || submitting}
                            >
                                Gửi <i className="fa fa-angle-right" />
                            </button>
                        </form>
                    </div>
                )}
            ></Form>
        </>
    );
};

export default ForgetPassRequireForm;
