import React, { useEffect, useState } from 'react';
import _lessonApis from '../../../apis/lesson.api';
import { GLOBAL } from '../../../constants/global.const';
import BcnPagination from '../../_Shares/Pagination';
import CommentItem from './Item';

const LessonCommentList = ({ lessonId, reloadCount, dataItem }) => {
    const [list, setList] = useState([]);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const resetValue = () => {
        setList([]);
        setTotal(0);
    };
    const fetchData = async () => {
        if (dataItem.isLastOfCourse) {
            return;
        }
        if (dataItem.isLastOfGroup) {
            const { status, data } = await _lessonApis.getListRateComment({
                lessonId,
                currentPage,
                pageSize: GLOBAL.COMMENT.PAGE_SIZE,
            });
            if (status !== 200) {
                resetValue();
                return;
            }
            setList(
                data.data.list.map((o) => {
                    return {
                        avatar: o.c_avatar,
                        ratePoint: o.lr_rate,
                        comment: o.lr_content,
                        customerName: o.c_name,
                    };
                })
            );
            setTotal(data.data.total);
        }
    };

    useEffect(() => {
        if ((lessonId || reloadCount) && dataItem.isLastOfGroup) {
            fetchData();
        }
    }, [lessonId, reloadCount, currentPage, dataItem]);

    const onPageChange = (currPage) => {
        setCurrentPage(currPage);
    };

    const showHtml = () => {
        let xhtml = null;

        if (list.length > 0) {
            xhtml = list.map((o, index) => {
                return <CommentItem dataItem={o} key={index} />;
            });
        }

        return xhtml;
    };

    return (
        <Fragment>
            {dataItem?.isLastOfGroup ? (
                <div className="course-details__bottom">
                    <div className="comment-one">
                        <h3 className="comment-one__title">
                            {total > 0
                                ? `${total} Bình luận`
                                : 'Chưa có bình luận nào'}{' '}
                        </h3>
                        {showHtml()}
                        <BcnPagination
                            total={total}
                            currentCount={list.length}
                            pageSize={GLOBAL.COMMENT.PAGE_SIZE}
                            currentPage={currentPage}
                            onChange={onPageChange}
                        ></BcnPagination>
                    </div>
                </div>
            ) : null}{' '}
        </Fragment>
    );
};

export default LessonCommentList;
