import React from 'react';

const QuizAnswerItem = ({ dataItem, doAnswer }) => {
    return (
        <div className="item" onClick={() => doAnswer(dataItem.ta_id)}>
            {dataItem.ta_content}
        </div>
    );
};

QuizAnswerItem.propTypes = {};

export default QuizAnswerItem;
