import React, { useEffect, useState } from 'react';

const LessonCompleteButton = ({ dataItem, onClick }) => {
    const [isShow, setIsShow] = useState(false);
    console.log(dataItem);
    useEffect(() => {
        if (!_.isEmpty(dataItem)) {
            const { isLastOfGroup, isRateLesson, isCompleted, isLastOfCourse } =
                dataItem;
            if (isLastOfCourse && !isCompleted) {
                setIsShow(true);
                return;
            }
            if (isLastOfGroup && !isRateLesson) {
                setIsShow(false);
                return;
            }
            setIsShow(!isCompleted);
            return;
        }
    }, [dataItem]);

    return (
        <Fragment>
            {isShow ? (
                <button
                    type="button"
                    className="thm-btn comment-form__btn btn-no-icon"
                    onClick={onClick}
                >
                    <i></i>
                    HOÀN THÀNH
                    <i></i>
                </button>
            ) : null}
        </Fragment>
    );
};

export default LessonCompleteButton;
