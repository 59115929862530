import React from 'react';
import './index.scss';

const TextAreaField = ({
    input,
    label,
    type,
    rows,
    meta: { touched, error, warning },
    ...custome
}) => (
    <div>
        <>
            {label ? (
                <label>
                    <h6 className="panel-title">{label}</h6>
                </label>
            ) : null}
        </>

        <div className="form-group comment-form__input-box">
            <textarea {...input} {...custome} type={type} rows={rows} />
            {touched &&
                ((error && <small className="text-danger">{error}</small>) ||
                    (warning && (
                        <small className="text-warning">{warning}</small>
                    )))}
        </div>
    </div>
);

export default TextAreaField;
