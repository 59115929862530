import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import _stringUtils from '../../../../utils/string.utils';

const { Option } = Select;
const propTypes = {
    label: PropTypes.string,
    onChange: PropTypes.func,
};

const listGender = [
    {
        value: 0,
        text: 'Nữ',
    },
    {
        value: 1,
        text: 'Nam',
    },
    {
        value: 2,
        text: 'Khác',
    },
];

const GenderSelect = ({
    label,
    defaultValue,
    onChange,
    onItemChange,
    isDisabled,
    isOldVer,
}) => {
    const [isFirst, setIsFirst] = useState(true);
    const [currSelectId, setCurrSelectId] = useState('');

    useEffect(() => {
        let selectId = '';
        if (defaultValue) {
            selectId = defaultValue;
        } else {
            selectId = listGender[0].value;
        }

        setCurrSelectId(selectId);
        setIsFirst(false);
        if (onChange) {
            onChange(selectId);
        }
        if (onItemChange) {
            onItemChange(listGender.find((o) => o.value === selectId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (defaultValue && !isFirst) {
            setCurrSelectId(defaultValue);
            if (onChange) {
                onChange(defaultValue);
            }
            if (onItemChange) {
                onItemChange(listGender.find((o) => o.value === defaultValue));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue]);

    const handleChange = (s) => {
        setCurrSelectId(s);
        if (onChange) {
            onChange(s);
        }
        if (onItemChange) {
            onItemChange(listGender.find((o) => o.value === s));
        }
    };

    const onSearch = (input, option) => {
        if (option) {
            return (
                _stringUtils
                    .nonAccentVietnamese(option.children.replace(/\s/g, ''))
                    .toLowerCase()
                    .indexOf(
                        nonAccentVietnamese(
                            input.replace(/\s/g, '')
                        ).toLowerCase()
                    ) >= 0
            );
        }
        return false;
    };

    const showOptionItem = () => {
        let xhtml = null;

        xhtml = listGender.map((item) => (
            <Option value={item.value} key={`gender-${item.value}`}>
                {item.text}
            </Option>
        ));

        return xhtml;
    };

    return (
        <Fragment>
            {label ? (
                <Fragment>
                    <label>
                        <h6 className="panel-title">{label}</h6>
                    </label>
                </Fragment>
            ) : null}
            {!isOldVer ? (
                <div className="form-group comment-form__input-box">
                    <Select
                        value={currSelectId}
                        onChange={(value) => handleChange(value)}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            onSearch(input, option)
                        }
                        showSearch
                        className="ctxh-select"
                        disabled={isDisabled}
                    >
                        {showOptionItem()}
                    </Select>
                </div>
            ) : (
                <div className="comment-form__input-box form-group select-old-style">
                    <Select
                        value={currSelectId}
                        onChange={(value) => handleChange(value)}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            onSearch(input, option)
                        }
                        showSearch
                        className="form-control"
                        disabled={isDisabled}
                    >
                        {showOptionItem()}
                    </Select>
                </div>
            )}
        </Fragment>
    );
};

GenderSelect.propTypes = propTypes;

export default GenderSelect;
