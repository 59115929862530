import _testApis from '../apis/test.api';
import { GLOBAL } from '../constants/global.const';
import _alertUtils, { SWAL } from '../utils/alert.util';

class Quiz {
    constructor(data) {
        if (!data) data = {};
        this.lessonId = data.lessonId || 0;
        this.testId = data.testId || 0;
        this.currentTest = {};
        this.currentQuest = {};
        this.listAnswer = [];
        this.isFinish = false;
    }

    /**
     * Lấy câu hỏi lần đầu tiên khi popup xuất hiện
     */
    async getQuestion() {
        if (this.lessonId <= 0) return;
        const { status, data } = await _testApis.getQuestion({
            lessonId: this.lessonId,
            testId: this.testId,
        });
        if (status !== 200) {
            _alertUtils.show(SWAL.TYPE.ERR, data.msg);
            return { isSuccess: false };
        }
        this.testId = data.data.currentTest.t_id;
        this.currentTest = data.data.currentTest;
        this.currentQuest = data.data.currentQuest;
        this.listAnswer = [];
        this.isFinish = false;

        return {
            isSuccess: true,
            data: data.data,
        };
    }
    // Trả lời câu hỏi
    async doAnswer(answerId) {
        if (answerId <= 0) return;
        const { status, data } = await _testApis.doAnswer({
            answerId: answerId,
            lessonId: this.lessonId,
            testId: this.testId,
        });

        if (status !== 200) {
            _alertUtils.show(SWAL.TYPE.ERR, data, msg);
            return;
        }

        this.listAnswer = [...this.listAnswer, data.testAnswerResult];
        let msg = 'Chính xác';
        let swalType = SWAL.TYPE.SUCCESS;
        const testType = this.currentTest.t_type;
        if (
            testType === GLOBAL.TEST_TYPE.BAI_KHAO_SAT ||
            testType === GLOBAL.TEST_TYPE.TINH_DIEM ||
            testType === GLOBAL.TEST_TYPE.NHAP_DAP_AN
        ) {
            msg = 'Đã trả lời';
        } else {
            if (!data.isCorrect) {
                msg = 'Sai rồi';
                swalType = SWAL.TYPE.ERR;
            }
        }
        const res = await _alertUtils.showTimer(swalType, msg);
        if (res.isDismissed) {
            _alertUtils.showLoading('Câu hỏi tiếp theo...');
            const res = await this.getNextQueston();
            _alertUtils.closeLoading();
            return res;
        }
        return {};
    }

    // Lấy câu hỏi tiếp theo
    async getNextQueston() {
        if (this.testId <= 0) return;
        const dataPost = {
            testId: this.testId,
            testQuestionId: this.currentQuest.tq_id,
            lessonId: this.lessonId,
        };
        const { status, data } = await _testApis.getNextQuestion(dataPost);
        if (status !== 200) {
            _alertUtils.show(SWAL.TYPE.ERR, data.msg);
            return;
        }
        const { currentTest, currentQuest, isFinish } = data.data;
        this.isFinish = isFinish;
        if (!isFinish) {
            this.currentQuest = currentQuest;
            this.currentTest = currentTest;
            this.testId = currentTest.t_id;
        } else {
            await this.doFinish();
        }

        return data.data;
    }
    // Kiểm tra có hoàn thành bài học sau khi bài kiểm tra hoàn thành hay không
    async doFinish() {
        if (!this.isFinish) return;
        await _testApis.doFinish({
            testId: this.testId,
            lessonId: this.lessonId,
        });
    }

    async getResult() {}
}

export default Quiz;
