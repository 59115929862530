import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';
import { Progress } from 'antd';
import _courseApis from '../../../apis/course.api';

const CourseSummaryRate = ({ courseId }) => {
    const [listRate, setListRate] = useState([]);
    const [percentagePoint, setPercentagePoint] = useState('');
    const fetchData = async () => {
        const { status, data } = await _courseApis.getRateSummary(courseId);
        if (status !== 200) {
            return;
        }
        setPercentagePoint(data.data.percentagePoint);
        setListRate(data.data.listRate);
    };
    useEffect(() => {
        if (courseId) {
            fetchData();
        }
    }, [courseId]);

    const showRate = () => {
        if (listRate.length === 0) return null;
        return listRate.map((item, index) => {
            return (
                <div className="rate-summary__item" key={index}>
                    <p className="desc">{item.name}</p>
                    <Progress
                        percent={item.percent}
                        status="active"
                        format={() => `${item.total}`}
                        className="orange-color"
                    />
                </div>
            );
        });
    };
    return (
        <div className="col-sm-12 rate-summary">
            <h2 className="rate-summary__title">Đánh giá khóa học</h2>
            {listRate.length === 0 ? (
                <p className="lesson-slide-box">Chưa có đánh giá</p>
            ) : (
                <div className="row">
                    <div className="col-sm-7">{showRate()}</div>
                    <div className="col-sm-5 rate-summary__score">
                        <Progress
                            type="circle"
                            status="active"
                            percent={100}
                            format={() => `${percentagePoint}`}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

CourseSummaryRate.propTypes = {};

export default CourseSummaryRate;

document
    .querySelectorAll('react-course-summary-rate')
    .forEach((domContainer) => {
        const courseId = parseInt(domContainer.dataset.courseid, 10);

        const root = createRoot(domContainer);
        root.render(<CourseSummaryRate courseId={courseId} />);
    });
