import _axiosService from '../services/axiosService';

const courseApis = {
    /**
     * Lấy trạng thái để hiện thị nút bắt đầu bài học
     * @param {Object} dataPost
     * @param {number} dataPost.courseId: mã khóa học
     * @returns
     */
    getStudyStartButton: (dataPost) => {
        return _axiosService.post(
            '/OnlineCourse/GetStartStudyButton',
            dataPost
        );
    },
    /**
     * Đăng ký khóa học
     * @param {Object} dataPost
     * @param {number} dataPost.courseId: mã khóa học
     * @returns
     */
    registerCourse: (dataPost) => {
        return _axiosService.post('/OnlineCourse/DoRegisterCourse', dataPost);
    },
    activeCourse: (dataPost) => {
        return _axiosService.post('/OnlineCourse/DoActiveCourse', dataPost);
    },
    getProgress: (dataPost) => {
        return _axiosService.post('/OnlineCourse/GetProgress', dataPost);
    },

    // Đánh giá cuối khóa học
    doRateFinish: (dataPost) => {
        return _axiosService.post(
            '/StudyCourse/DoRatingFinishCourse',
            dataPost
        );
    },
    getCommentList: (dataPost) => {
        return _axiosService.post('/OnlineCourse/GetCommentList', dataPost);
    },
    getRateSummary: (courseId) => {
        return _axiosService.post(`/OnlineCourse/GetRateSummary/${courseId}`);
    },
    getRecommendList: () => {
        return _axiosService.post(`/OnlineCourse/GetRecommendList`);
    },
};

export default courseApis;
