import React, { useState } from 'react';
import { createRoot } from 'react-dom/client';
import FortgetPassChangePassForm from './ChangePassForm';
import ForgetPassCodeForm from './CodeForm';
import ForgetPassRequireForm from './RequireForm';

const ForgetPasswordForm = () => {
    const [step, setStep] = useState(1);
    const [tokenInfo, setTokenInfo] = useState({});

    console.log(tokenInfo);

    const onChangeStep = (nextStep, tokenData) => {
        setStep(nextStep);
        if (tokenData) {
            setTokenInfo((prevState) => {
                return {
                    ...prevState,
                    ...tokenData,
                };
            });
        }
    };

    const showHtml = () => {
        switch (step) {
            case 1: {
                return (
                    <>
                        <ForgetPassRequireForm
                            onChangeStep={onChangeStep}
                            tokenInfo={tokenInfo}
                        />
                    </>
                );
            }
            case 2: {
                return (
                    <ForgetPassCodeForm
                        tokenInfo={tokenInfo}
                        onChangeStep={onChangeStep}
                    />
                );
            }
            case 3: {
                return (
                    <FortgetPassChangePassForm
                        tokenInfo={tokenInfo}
                        onChangeStep={onChangeStep}
                    />
                );
            }
            default: {
                return null;
            }
        }
    };

    return <div id="forget-pass-form">{showHtml()}</div>;
};

export default ForgetPasswordForm;

document
    .querySelectorAll('react-forget-password-form')
    .forEach((domContainer) => {
        const root = createRoot(domContainer);
        root.render(creElm(ForgetPasswordForm, {}));
    });
