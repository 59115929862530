import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './_style.scss';
import { createRoot } from 'react-dom/client';

const StarPercentage = ({ width }) => {
    const fillRef = useRef();
    const [fillWidth, setFillWidth] = useState({});

    useEffect(() => {
        setFillWidth({
            width: `${fillRef.current.offsetWidth}px`,
        });
    }, [width]);

    return (
        <>
            {width === 0 ? null : (
                <div className="star-percentage" style={fillWidth}>
                    <div
                        className="fill-ratings"
                        style={{ width: `${width}%` }}
                    >
                        <span ref={fillRef}>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </span>
                    </div>
                    <div className="empty-ratings">
                        <span>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </span>
                    </div>
                </div>
            )}
        </>
    );
};

StarPercentage.propTypes = {};

export default StarPercentage;

document.querySelectorAll('react-star-percentage').forEach((domContainer) => {
    const width = parseInt(domContainer.dataset.width, 10);

    const root = createRoot(domContainer);
    root.render(<StarPercentage width={width} />);
});
