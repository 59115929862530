export const GLOBAL = {
    COMMENT: {
        PAGE_SIZE: 15,
    },
    TEST_TYPE: {
        BAI_KHAO_SAT: 1,
        TINH_TI_LE: 2,
        TINH_DIEM: 3,
        CUOI_KHOA: 4,
        NHAP_DAP_AN: 5,
    },
    IMAGE_RESIZE_CONFIG: {
        MAX_WIDTH: 1200,
        MAX_HEIGHT: 600,
        QUALITY: {
            PERCENT_10: 10,
            PERCENT_20: 20,
            PERCENT_30: 30,
            PERCENT_40: 40,
            PERCENT_50: 50,
            PERCENT_60: 60,
            PERCENT_70: 70,
            PERCENT_80: 80,
            PERCENT_90: 90,
            PERCENT_100: 100,
        },
        ROTATION: {
            DEG_0: 0,
            DEG_90: 90,
            DEG_180: 180,
            DEG_270: 270,
            DEG_360: 360,
        },
        OUT_PUT_TYPE: { BASE64: 'base64', BLOB: 'blob', FILE: 'file' },
    },
    IMAGE_TYPE: {
        JPEG: 'JPEG',
        PNG: 'PNG',
        GIF: 'GIF',
        SVG: 'SVG',
    },
};
