import React from 'react';
import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';
import { Field, Form } from 'react-final-form';
import InputField from '../../_Shares/FormHelpers/InputField';
import validate from './formValidate';
import '../../../assets/css/course.scss';
import _courseApis from '../../../apis/course.api';
import _alertUtils from '../../../utils/alert.util';

const CourseActiveForm = ({ courseId }) => {
    const onSubmitForm = async (dataForm) => {
        _alertUtils.showLoading('Đang kích hoạt...');
        var dataPost = {
            code: dataForm.formCode,
            courseId,
        };
        const { status, data } = await _courseApis.activeCourse(dataPost);
        if (status !== 200) {
            _alertUtils.show(SWAL_CONST.TYPE.ERR, data.msg);
            return;
        }
        const ridirectToFirstLesson = () => {
            window.location.href = data.firstUrl;
        };
        _alertUtils.closeLoading();
        _alertUtils.showTimerFunc({
            type: SWAL_CONST.TYPE.SUCCESS,
            title: 'Đã kích hoạt khóa học thành công',
            msg: `<p>Hệ thống tự động chuyển sang bài học đầu tiên trong <strong></strong> giây nữa</p>`,
            func: ridirectToFirstLesson,
            timer: 5000,
        });
    };
    return (
        <div className="lesson-slide-box">
            <p className="desc">
                <b>Mã kích hoạt khóa học</b> đã được gửi tới hộp thư của bạn.
                Vui lòng nhập mã và nhấn vào nút <b>“Kích hoạt”</b> để bắt đầu
                khóa học
            </p>
            <p className="desc mb-30">
                Nếu bạn không nhận được mã kích hoạt, vui lòng kiểm tra{' '}
                <b>Hộp thư rác/Spam</b> hoặc liên hệ với chúng tôi qua email:
                <b>
                    <a href="mailto:ctxh@rtccd.org.vn"> ctxh@rtccd.org.vn</a>
                </b>
            </p>
            <Form
                onSubmit={onSubmitForm}
                validate={validate}
                render={({ handleSubmit, invalid, submitting }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-xl-12">
                                <Field
                                    name="formCode"
                                    component={InputField}
                                    className="form-control"
                                    type="text"
                                    placeholder="Vui lòng nhập mã kích hoạt"
                                />
                            </div>
                        </div>

                        <div className="comment-form__btn-box form-group text-center">
                            <button
                                type="submit"
                                className="thm-btn comment-form__btn text-center"
                                disabled={invalid || submitting}
                            >
                                Kích hoạt
                                <i className="fa fa-angle-right" />
                            </button>
                        </div>
                    </form>
                )}
            ></Form>
        </div>
    );
};

CourseActiveForm.propTypes = {};

export default CourseActiveForm;

document
    .querySelectorAll('react-active-course-form-v2')
    .forEach((domContainer) => {
        const courseId = parseInt(domContainer.dataset.courseid, 10);
        const root = createRoot(domContainer);
        root.render(<CourseActiveForm courseId={courseId} />);
    });
