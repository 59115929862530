import React, { useEffect, useState } from 'react';
import _courseApis from '../../../apis/course.api';
import RecommendCourseItem from './Item';

const RecommendCourse = () => {
    const [listCourse, setListCourse] = useState([]);
    const fetchData = async () => {
        const { status, data } = await _courseApis.getRecommendList();
        if (status !== 200) return;
        setListCourse(data.data);
    };
    useEffect(() => {
        fetchData();
    }, []);
    const showHtml = () => {
        if (listCourse.length === 0) return null;
        return listCourse.map((item, index) => {
            return (
                <div
                    class="col-xl-6 col-lg-6 wow fadeInUp course-item"
                    data-wow-delay="100ms"
                    key={index}
                >
                    <RecommendCourseItem dataItem={item} />
                </div>
            );
        });
    };
    return (
        <>
            {listCourse.length === 0 ? null : (
                <>
                    <div className="mb-50"></div>
                    <div className="lesson-slide-box text-center">
                        <p>Chúc mừng bạn đã hoàn thành khóa học</p>
                        <p>
                            Chúng tôi còn rất nhiều khóa học hay và bổ ích bên
                            dưới, bạn có thể tham khảo nhé
                        </p>
                    </div>
                    <div className="mb-50"></div>
                    <div className="row">{showHtml()}</div>
                </>
            )}
        </>
    );
};

RecommendCourse.propTypes = {};

export default RecommendCourse;
