import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import _lessonApis from '../../apis/lesson.api';
import CourseProgressInfo from '../Course/ProgressInfo';
import LessonCommentList from './CommentList/LessonComment';
import DirectGroupBtn from './DirectGroupButton';
import LastGroupRate from './RateComment/LastGroupRate';
import CourseCommentList from './CommentList/CourseComment';
import RecommendCourse from '../Course/RecommendCourse';

const LessonFooter = ({ courseId, lessonId, isLastOfCourse }) => {
    const [infoDetail, setInfoDetail] = useState({});
    const [reloadCount, setReloadCound] = useState(0);

    const fetchData = async () => {
        const { status, data } = await _lessonApis.getDirectGroupButton({
            courseId,
            lessonId,
        });
        if (status !== 200) {
            setInfoDetail({});
            return;
        }
        setInfoDetail(data.data);
    };
    useEffect(() => {
        if (courseId && lessonId) {
            fetchData();
        }
    }, [courseId, lessonId]);

    console.log(infoDetail);

    const onCommentSuccess = () => {
        fetchData();
        setReloadCound((prevState) => {
            return (prevState += 1);
        });
    };

    const onReload = () => {
        fetchData();
    };

    return (
        <>
            <DirectGroupBtn
                dataItem={infoDetail}
                lessonId={lessonId}
                onReload={onReload}
            />
            {infoDetail?.isCompleted && infoDetail?.isLastOfCourse ? (
                <RecommendCourse currentCourseId={courseId} />
            ) : null}
            {!isLastOfCourse ? (
                <LastGroupRate
                    dataItem={infoDetail}
                    onCommentSuccess={onCommentSuccess}
                    lessonId={lessonId}
                    courseId={courseId}
                />
            ) : null}

            <CourseProgressInfo courseId={courseId} />
            {isLastOfCourse ? (
                <CourseCommentList courseId={courseId} />
            ) : (
                <LessonCommentList
                    dataItem={infoDetail}
                    lessonId={lessonId}
                    reloadCount={reloadCount}
                />
            )}
        </>
    );
};

LessonFooter.propTypes = {};

export default LessonFooter;

document.querySelectorAll('react-lesson-footer-v2').forEach((domContainer) => {
    const courseId = parseInt(domContainer.dataset.courseid, 10);
    const lessonId = parseInt(domContainer.dataset.lessonid, 10);
    const isLastOfCourse =
        (domContainer.dataset.isLastOfCourse + '').toLowerCase() === 'true';

    const root = createRoot(domContainer);
    root.render(
        <LessonFooter
            courseId={courseId}
            lessonId={lessonId}
            isLastOfCourse={isLastOfCourse}
        />
    );
});
