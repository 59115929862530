import React from 'react';
import { GLOBAL } from '../../../constants/global.const';

const QuizResult = ({ quizInfo }) => {
    const showInfo = () => {
        const { listAnswer, currentTest } = quizInfo;
        const testType = currentTest.t_type;

        switch (testType) {
            case GLOBAL.TEST_TYPE.TINH_TI_LE:
            case GLOBAL.TEST_TYPE.CUOI_KHOA: {
                const listCorrectAnswer = listAnswer.filter(
                    (o) => o.is_correct
                );
                const rateAnswer =
                    Math.round(
                        ((listCorrectAnswer.length / listAnswer.length) * 100 +
                            Number.EPSILON) *
                            100
                    ) / 100;
                const isPass = rateAnswer >= currentTest.t_passrate;

                return (
                    <>
                        <h2>Chúc mừng bạn đã hoàn thành bài kiểm tra</h2>
                        <p>
                            Bạn đã trả lời đúng{' '}
                            <h2 class="text-theme-colored2">
                                <span class="text-primary">
                                    {listCorrectAnswer.length}
                                </span>
                                /{listAnswer.length}
                                <span class="text-primary">
                                    ({rateAnswer} %)
                                </span>
                            </h2>
                        </p>
                        <h3>
                            Yêu cầu phải đạt:{' '}
                            <span class="text-primary">
                                {currentTest.t_passrate} %
                            </span>
                        </h3>
                        <h1>
                            {isPass ? (
                                <span class="text-primary">ĐẠT</span>
                            ) : (
                                <span class="text-danger">KHÔNG ĐẠT</span>
                            )}
                        </h1>
                    </>
                );
            }
            case GLOBAL.TEST_TYPE.TINH_DIEM: {
                return null;
            }

            default: {
                return null;
            }
        }
    };
    return <div className="result-content">{showInfo()}</div>;
};

QuizResult.propTypes = {};

export default QuizResult;
