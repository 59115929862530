import React from 'react';

const LessonNextButton = ({ link }) => {
    const showHtml = () => {
        let xhtml = null;
        if (link) {
            return (
                <a href={link} className="thm-btn comment-form__btn">
                    TIẾP
                    <i className="fa fa-angle-right" />
                </a>
            );
        }
        return xhtml;
    };
    return <Fragment>{showHtml()}</Fragment>;
};

export default LessonNextButton;
