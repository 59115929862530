import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';

const LoginForm = () => {
    useEffect(() => {
        const form_name = '#login_form';
        if ($(form_name).length > 0) {
            var fv = $(form_name)
                .formValidation({
                    framework: 'bootstrap',
                    err: {
                        /* container: 'tooltip'*/
                    },
                    icon: {
                        //valid: 'fa fa-check',
                        //invalid: 'fa fa-times',
                        //validating: 'fa fa-refresh'
                    },
                    fields: {
                        username: {
                            validators: {
                                notEmpty: {
                                    message:
                                        'Tên đăng nhập không được để trống',
                                },
                            },
                        },
                        password: {
                            validators: {
                                notEmpty: {
                                    message: 'Mật khẩu không được để trống',
                                },
                            },
                        },
                    },
                })
                .on('success.form.fv', function (e) {
                    e.preventDefault();

                    var dataPost = {
                        username: $(`${form_name} #username`).val(),
                        password: $(`${form_name} #password`).val(),
                    };

                    swalShowLoading('<p>Đang xử lý</p>');

                    _customerApis.login(dataPost).then(({ status, data }) => {
                        $(form_name).data('formValidation').resetForm();
                        if (status !== 200) {
                            mySwal(SWAL_CONST.TYPE.WARNING, data.msg);
                            return;
                        }

                        const ridirectToMyCourse = () => {
                            window.location.href = '/khoa-hoc-cua-toi';
                        };
                        mySwalShowTimer({
                            type: SWAL_CONST.TYPE.SUCCESS,
                            title: 'Đăng nhập thành công',
                            msg: `<p>Tự động chuyển sang <b>Khóa học của tôi</b> trong <strong></strong> giây</p>`,
                            func: ridirectToMyCourse,
                            timer: 5000,
                        });
                    });
                });
        }
    }, []);

    return (
        <form
            id="login_form"
            className="comment-one__form contact-form-validated"
            noValidate="novalidate"
        >
            <div className="row">
                <div className="col-xl-6">
                    <div className="comment-form__input-box form-group">
                        <input
                            type="text"
                            placeholder="Tên đăng nhập (Email/Số điện thoại)"
                            name="username"
                            id="username"
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="col-xl-6">
                    <div className="comment-form__input-box form-group">
                        <input
                            type="password"
                            placeholder="Mật khẩu"
                            name="password"
                            id="password"
                            className="form-control"
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-12">
                    <div
                        className="comment-form__btn-box"
                        style={{ float: 'left' }}
                    >
                        <ul className="list-unstyled contact-page-two__info mt-10 mb-20">
                            <li>
                                <div
                                    className="text"
                                    style={{ marginLeft: '0px' }}
                                >
                                    <p>
                                        Bạn không nhớ mật khẩu ?{' '}
                                        <a href="/quen-mat-khau">
                                            <b>QUÊN MẬT KHẨU</b>
                                        </a>
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div
                        className="comment-form__btn-box"
                        style={{ float: 'right' }}
                    >
                        <ul className="list-unstyled contact-page-two__info mt-10 mb-20">
                            <li>
                                <div
                                    className="text"
                                    style={{ marginLeft: '0px' }}
                                >
                                    <p>
                                        Bạn chưa có tài khoản?{' '}
                                        <a href="/dang-ky">
                                            <b>ĐĂNG KÝ NGAY</b>
                                        </a>
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="mb-20"></div>
                <div className="col-xl-12">
                    <div className="comment-form__btn-box">
                        <button
                            type="submit"
                            className="thm-btn comment-form__btn"
                        >
                            ĐĂNG NHẬP <i className="fa fa-angle-right" />
                        </button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default LoginForm;

document.querySelectorAll('react-web-login-form').forEach((domContainer) => {
    const root = createRoot(domContainer);
    root.render(creElm(LoginForm, {}));
});
