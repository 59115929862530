import React, { useEffect, useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { Field, Form } from 'react-final-form';
import AvatarField from '../../_Shares/FormHelpers/ImageField';
import InputField from '../../_Shares/FormHelpers/InputField';
import GenderSelectField from '../../_Shares/Select/GenderSelect/Field';
import DatePickerField from '../../_Shares/FormHelpers/DatePickerField';
import dayjs from 'dayjs';
import { Button } from 'antd';
import {
    EditOutlined,
    ReloadOutlined,
    SaveOutlined,
    CloseOutlined,
} from '@ant-design/icons';
import customerApis from '../../../apis/customer.api';
import alertUtils, { SWAL } from '../../../utils/alert.util';
import ChangePassPopup from '../ChangePassPopup';
import validate from './validate';

const UserInfo = () => {
    // console.log(crypto.randomUUID());
    const [isEdit, setIsEdit] = useState(false);
    const [userInfo, setUserInfo] = useState({});
    const [showPopup, setShowPopup] = useState(false);
    const imgFile = useRef();
    useEffect(() => {
        customerApis.getInfo().then(({ status, data }) => {
            if (status !== 200) return;
            setUserInfo(data.data);
        });
    }, []);

    const onSubmitForm = (dataSave) => {
        alertUtils.showLoading('Đang lưu...');
        dataSave = {
            ...dataSave,
            imgBase64: imgFile?.current?.base64Content,
            fileName: imgFile?.current?.file?.name,
            c_int_dateofbirth: dayjs(dataSave.c_dateofbirth).unix(),
        };

        customerApis.updateInfo(dataSave).then(({ status, data }) => {
            if (status !== 200) {
                alertUtils.show(SWAL.TYPE.ERR, data.msg);
                return;
            }
            alertUtils.show(
                SWAL.TYPE.SUCCESS,
                'Đã cập nhật thông tin thành công'
            );
            setIsEdit(false);
        });
    };

    const onImageChange = (e) => {
        imgFile.current = e;
    };

    const onShowPopup = () => {
        setShowPopup(true);
    };

    const onClosePopup = () => {
        setShowPopup(false);
    };
    return (
        <div className="user-info">
            <Form
                onSubmit={onSubmitForm}
                initialValues={userInfo}
                validate={validate}
                render={({ handleSubmit, invalid, submitting }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-xl-4">
                                <Field
                                    name="c_avatar"
                                    component={AvatarField}
                                    className="form-control"
                                    isDisabled={!isEdit}
                                    onChange={onImageChange}
                                    currentImage={userInfo.c_avatar}
                                />
                            </div>
                            <div className="col-xl-8">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <Field
                                            name="c_name"
                                            component={InputField}
                                            className="form-control"
                                            type="text"
                                            label="Họ tên"
                                            isDisabled={!isEdit}
                                        />
                                        <Field
                                            name="c_email"
                                            component={InputField}
                                            className="form-control"
                                            type="text"
                                            label="Email"
                                            isDisabled={!isEdit}
                                        />
                                        <Field
                                            name="c_telephone"
                                            component={InputField}
                                            className="form-control"
                                            type="text"
                                            label="Số điện thoại"
                                            isDisabled={!isEdit}
                                        />
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <Field
                                                    name="c_gender"
                                                    component={
                                                        GenderSelectField
                                                    }
                                                    className="form-control"
                                                    type="text"
                                                    label="Giới tính"
                                                    isDisabled={!isEdit}
                                                />
                                            </div>
                                            <div className="col-sm-6">
                                                <Field
                                                    name="c_dateofbirth"
                                                    component={DatePickerField}
                                                    className="form-control"
                                                    type="text"
                                                    label="Ngày sinh"
                                                    maxDate={dayjs(new Date())}
                                                    isDisabled={!isEdit}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <Field
                                            name="c_address"
                                            component={InputField}
                                            className="form-control"
                                            type="text"
                                            label="Địa chỉ"
                                            isDisabled={!isEdit}
                                        />
                                    </div>
                                    <div className="mb-20"></div>
                                    <div className="col-sm-12">
                                        <div className="btn-wrap text-right">
                                            <Button
                                                type="info"
                                                icon={<ReloadOutlined />}
                                                onClick={onShowPopup}
                                            >
                                                Đổi mật khẩu
                                            </Button>
                                            {isEdit ? (
                                                <>
                                                    <Button
                                                        type="primary"
                                                        danger
                                                        icon={<CloseOutlined />}
                                                        onClick={() => {
                                                            setIsEdit(false);
                                                            setUserInfo(
                                                                (prevState) => {
                                                                    return {
                                                                        ...prevState,
                                                                        randomId:
                                                                            crypto.randomUUID(),
                                                                    };
                                                                }
                                                            );
                                                        }}
                                                    >
                                                        Hủy thao tác
                                                    </Button>
                                                    <Button
                                                        type="primary"
                                                        icon={<SaveOutlined />}
                                                        onClick={handleSubmit}
                                                        disabled={
                                                            invalid ||
                                                            submitting
                                                        }
                                                    >
                                                        Lưu lại
                                                    </Button>
                                                </>
                                            ) : (
                                                <Button
                                                    type="primary"
                                                    icon={<EditOutlined />}
                                                    onClick={() => {
                                                        setIsEdit(true);
                                                    }}
                                                >
                                                    Chỉnh sửa
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                )}
            ></Form>
            {showPopup ? (
                <ChangePassPopup isOpen={showPopup} onClose={onClosePopup} />
            ) : null}
        </div>
    );
};

export default UserInfo;

document.querySelectorAll('react-user-info').forEach((domContainer) => {
    const root = createRoot(domContainer);
    root.render(<UserInfo />);
});
