import React, { Component } from 'react';
import alertUtils, { SWAL } from '../../../../utils/alert.util';
import imageUtils from '../../../../utils/image.utils';
import './index.scss';

const AvatarField = ({ onChange, currentImage, isDisabled }) => {
    const handleChange = async (e) => {
        const file = e.target.files[0];
        console.log(file);
        if (!file) return;

        const fileTypeParams = file.type.split('/');
        if (fileTypeParams[0] !== 'image') {
            alertUtils.show(
                SWAL.TYPE.WARNING,
                'File bạn vừa chọn không phải hình ảnh! Xin vui lòng chọn lại'
            );
            return;
        }
        var size = parseFloat(file.size / 1024).toFixed(2);
        if (size > 5000) {
            alertUtils.show(
                SWAL.TYPE.WARNING,
                'File bạn vừa chọn đã vượt quá 5MB! Xin vui lòng chọn lại'
            );
            return;
        }
        const img = document.getElementById('jsAvatarImg');
        const reader = new FileReader();
        reader.onloadend = () => {
            img.src = reader.result;
        };
        reader.readAsDataURL(file);

        if (onChange) {
            imageUtils.resize(onChange, file);
            //callResizeImage(onChange, file);
            //onChange(file);
        }
    };

    if (!currentImage) {
        currentImage = '/images/user.png';
    }

    return (
        <div
            className="thumb thumb-rounded thumb-slide"
            style={{ width: '90%' }}
        >
            <img id="jsAvatarImg" src={currentImage} alt="" />
            {!isDisabled ? (
                <div className="caption">
                    <span>
                        <div className="file-input-wrapper">
                            <button
                                type="button"
                                className="btn-file-input btn bg-success-400 btn-icon btn-xs"
                            >
                                <i className="icon-plus2" />
                            </button>
                            <input
                                type="file"
                                name="file"
                                onChange={handleChange}
                            />
                        </div>
                    </span>
                </div>
            ) : null}
        </div>
    );
};
export default AvatarField;
