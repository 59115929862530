import React, { useEffect, useState } from 'react';
const JobSelect = (props) => {
    const [list, setList] = useState([]);

    const fetchData = async () => {
        const { data, status } = await _jobApis.getList();
        if (status !== 200) {
            setList([]);
            return;
        }
        setList(data.data);
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (props.id) {
            const idName = $(`#${props.id}`);
            idName.select2();
        }
    }, [props.id]);

    const showHtml = () => {
        let xhtml = null;
        if (list.length > 0) {
            xhtml = list.map((o, index) => {
                return (
                    <option key={index} value={o.jo_id}>
                        {o.jo_name}
                    </option>
                );
            });
        }
        return xhtml;
    };

    return (
        <div className="comment-form__input-box form-group" id="my-select-2">
            <label>Nghề nghiệp</label>
            <select className="form-control" {...props}>
                {showHtml()}
            </select>
        </div>
    );
};

export default JobSelect;
