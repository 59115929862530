import React from 'react';
import PropTypes from 'prop-types';

const RecommendCourseItem = ({ dataItem }) => {
    return (
        <div className="services-two__single">
            <div className="services-two__img-box">
                <a href={`/chi-tiet-khoa-hoc/${dataItem.co_code}`}>
                    <span className="services-two__img">
                        <img src={dataItem.co_imageid} alt="" />
                    </span>
                </a>
            </div>
            <div className="services-two__content">
                <h5 className="services-two__title">
                    <a href={`/chi-tiet-khoa-hoc/${dataItem.co_code}`}>
                        {dataItem.co_name}
                    </a>
                </h5>

                <p className="services-two__text">{dataItem.co_summary}</p>
                <a
                    href={`/chi-tiet-khoa-hoc/${dataItem.co_code}`}
                    className="services-two__btn"
                >
                    Xem chi tiết
                </a>
            </div>
        </div>
    );
};

RecommendCourseItem.propTypes = {};

export default RecommendCourseItem;
